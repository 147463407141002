@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

body {
    font-family: 'Maven Pro', sans-serif;
    background-color: #F3f5f9;
}

.shadow2 {
    box-shadow: 0 0 7px rgba(177, 187, 208, 0.9) !important;
}

.loader {
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.border-s {
    border: 1px solid #ced4da;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.loader h1 {
    color: rgb(188, 188, 188);
}

.modal-content {
    opacity: .9;
}

.deleteMainProjectItem {
    position: absolute;
    top: 0;
    right: 0;
}

.btn-close {
    transform: translate(8px, -13px);
    padding: 0.8rem;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    opacity: 1;
    transition: all .23s ease .1s;
    position: relative;
    margin: -0.4rem -0.7rem -0.4rem auto;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/.75rem auto no-repeat;
    border: 0;
    border-radius: 0.357rem;
    color: currentColor;
    box-sizing: content-box;
}

.btn-close:hover {
    transform: translate(-3px, -5px);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.navbarheader {
    width: 25%;
    background-color: #F3f5f9;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    transition: 1s;
}

.navbarheader-close {
    width: 25%;
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    transition: 1s;
    animation: to-left-2 0.3s linear forwards;
}

.fileCards .position-relative {
    transition: .4s;
    cursor: pointer;
}

.fileCards .position-relative:hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

@keyframes to-left-2 {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}


.mobil-menu-view .logo,
.navbarheader-close .logo,
.navbarheader .logo {
    /* width: 94%; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border-bottom: 0.3px solid rgb(52, 52, 52); */
    padding-top: 10px;
    padding-left: 30px;
    font-weight: 900;
    padding-bottom: 10px;
}

.mobil-menu-view .logo h2,
.navbarheader-close .logo h2,
.navbarheader .logo h2 {
    /* color: rgb(188, 188, 188); */
}

.mobil-menu-view .logo svg,
.navbarheader-close .logo svg,
.navbarheader .logo svg {
    color: rgb(188, 188, 188);
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.cke_notifications_area {
    display: none !important;
}

.mobil-menu-view .menus,
.navbarheader-close .menus,
.navbarheader .menus {
    border-bottom: 0.3px solid rgb(223, 223, 223);
    padding-bottom: 20px;
    width: 80%;
    margin-left: 5%;
}

.mobil-menu-view .menus div,
.navbarheader-close .menus div,
.navbarheader .menus div {
    margin-bottom: 13px;
    cursor: pointer;
    letter-spacing: 1px;
    transition: 0.5s;
}

.mobil-menu-view .menus div:hover,
.navbarheader-close .menus div:hover,
.navbarheader .menus div:hover {
    letter-spacing: 1.5px;
}

.mobil-menu-view .menus div:hover,
.navbarheader-close .menus div:hover,
.navbarheader .menus div:hover svg {
    color: #17a2b8;
}

.mobil-menu-view .menus div:hover,
.navbarheader-close .menus div:hover,
.navbarheader .menus div:hover span {
    color: #17a2b8;
}

.mobil-menu-view .menus hr,
.navbarheader-close .menus hr,
.navbarheader .menus hr {
    color: rgb(188, 188, 188);
}

.mobil-menu-view .menus div span,
.navbarheader-close .menus div span,
.navbarheader .menus .enable-menu span {
    color: #17a2b8;
}

.mobil-menu-view .menus div span,
.navbarheader-close .menus div span,
.navbarheader .menus .enable-menu svg {
    color: #17a2b8;
}

.mobil-menu-view .menus div span,
.navbarheader-close .menus div span,
.navbarheader .menus div svg {
    transition: .4s;
}

.mobil-menu-view .menus div span,
.navbarheader-close .menus div span,
.navbarheader .menus div span {
    color: black;
    font-size: 16px;
    transition: .4s;
}

.mobil-menu-view .down svg,
.navbarheader-close .down svg,
.navbarheader .down svg {
    height: 20px;
    width: 20px;
    color: rgb(188, 188, 188);
    margin-right: 10px;
    margin-top: -4px;
}

.mobil-menu-view .down .baslik,
.navbarheader-close .down .baslik,
.navbarheader .down .baslik {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 5%;
    color: rgb(93, 93, 93);
}

.mobil-menu-view .template,
.navbarheader-close .template,
.navbarheader .template {
    margin-top: 10px;
    left: 10%;
    margin-bottom: 10px;
}

.mobil-menu-view .template span,
.navbarheader-close .template span,
.navbarheader .template span {
    color: rgb(93, 93, 93);
    font-size: 13px;
}

.mobil-menu-view .template div,
.navbarheader-close .template div,
.navbarheader .template div {
    margin-top: -5px;
}

.mobil-menu-view .template a,
.navbarheader-close .template a,
.navbarheader .template a {
    text-decoration: none;
    color: rgb(188, 188, 188);
    transition: 0.6s;
    font-size: 13px;
}

.mobil-menu-view .template a:hover,
.navbarheader-close .template a:hover,
.navbarheader .template a:hover {
    text-decoration: underline;
}

.close-menu {
    width: 60px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
    align-items: center;
    text-align: center;
}

.close-menu svg {
    cursor: pointer;
    transition: 0.3s;
}

.close-menu svg:hover {
    margin-left: 5px;
}

.table-logo {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 18px;
}

.close-menu .menu {
    border-bottom: 0.3px solid rgb(93, 93, 93);
    padding-top: 10px;
    padding-bottom: 10px;
}

.close-menu .menu svg {
    height: 40px;
    width: 40px;
    color: rgb(188, 188, 188);
}

.close-menu .menuler {
    margin-top: 30px;
    border-bottom: 1px solid rgb(52, 52, 52);
}

.close-menu .menuler div {
    margin-bottom: 20px;
}

.close-menu .menuler svg {
    height: 30px;
    width: 30px;
    color: rgb(188, 188, 188);
}

.close-menu .settings {
    margin-top: 30px;
}

.close-menu .settings div {
    margin-bottom: 20px;
}

.close-menu .settings svg {
    height: 30px;
    width: 30px;
    color: rgb(188, 188, 188);
}

@media screen and (max-width: 1085px) {

    .mobil-menu-view .menus div span,
    .navbarheader-close .menus div span,
    .navbarheader .menus div span {
        font-size: 13px;
    }
}

@media screen and (max-width: 950px) {
    .navbarheader {
        width: 300px;
    }
}

.mobil-menu {
    display: none;
}

.right-content .card span {
    color: grey;
}

@media screen and (max-width: 970px) {

    .mobil-menu-view .logo,
    .navbarheader-close .logo,
    .navbarheader .logo {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        padding: 0 20px;
    }

    .mobil-menu-view .logo img {
        width: 80% !important;
        filter: drop-shadow(2px 4px 6px black) !important;
    }

    .mobil-menu-view .menus,
    .navbarheader-close .menus,
    .navbarheader .menus {
        border-bottom: 0.3px solid rgb(72 72 72);
    }

    .right-content.mb-5 button {
        font-size: 13px;
    }

    .mobil-menu-view .menus div span,
    .navbarheader-close .menus div span,
    .navbarheader .menus div span {
        color: white;
    }

    .w-100.pb-5.row {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right-content h3 {
        font-size: 25px;
    }

    .right-content .card h4 {
        font-size: 20px;
    }

    .right-content .card span {
        color: grey;
        font-size: 14px;
    }

    .col-sm-9.col-md-9.col-xl-9 {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .navbarheader,
    .navbarheader-close,
    .close-menu {
        display: none;
    }

    .mobil-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        height: 60px;
        width: 100%;
        align-items: center;
        background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
        color: rgb(188, 188, 188);
    }

    .mobil-menu h2 {
        font-size: 25px;
    }

    .mobil-menu svg {
        height: 30px;
        width: 30px;
    }

    .mobil-menu-view h2 {
        font-size: 25px;
    }
}

.mobil-menu-view {
    z-index: 999999;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
}

.login {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
}

.login .card {
    width: 500px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.login .password {
    display: flex;
}

.login .password .pass {
    width: 90%;
}

.login .password .pass input {
    border-right: 0px;
}

.login .password .symbol {
    width: 10%;
    align-items: center;
    text-align: center;
    border: 1px solid #ced4da;
    border-left: 0px;
    border-radius: .25rem;
    margin-left: -5px;
    padding-top: 4px;
    padding-right: 33px;
}

.login button {
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
    letter-spacing: 1px;
}

@media screen and (max-width: 970px) {
    .login {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100vh;
        padding-left: 5%;
        padding-right: 5%;
        background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
    }

    .login h1 {
        font-size: 25px;
    }

    .login span {
        font-size: 15px;
    }
}

/* Home Page */

.right-content {
    width: 96%;
    margin-left: 2%;
    padding-top: 20px;
}

.right-content .header {
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
    padding-bottom: 15px;
}

.right-content .header h4 {
    color: white;
}

.right-content .header .card-subtitle {
    color: #ced4da;
    padding-left: 20px;
    margin-top: -15px;
}

.right-content .maincontent {
    padding: 10px;
}

.right-content .maincontent button {
    background-color: #ffbb38;
    border: 0;
    width: 20vh;
}

/* Home Page Bitiş */

/* Foto galeri */

@media (max-width:600px) {
    .foto-gallery {
        margin-top: 60px !important;
    }

    .right-content {
        margin-top: 70px;
        width: 100%;
        padding-top: 20px;
    }

    .row {
        margin: 0;
        padding: 0;
    }
}

.foto-gallery .header {
    background: linear-gradient(90deg, rgb(1, 0, 15) 0%, rgb(1, 22, 26) 35%, rgb(1, 0, 15) 100%);
}

input[type='text'] {
    font-size: 13px;
}